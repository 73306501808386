import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Loader, Style as PStyle } from "priceit-ui";
import { WrapperLoader } from "./Routes.style";
import { Navigate } from "react-router";
import { createEventAnalyticsMutation } from "../../config/analytics/graphQl/mutation";
import "../services/NumberFormatter";
import { RecoilRoot } from "recoil";
import { Style } from "../index.style";
import { AuthenticatedRoute } from "@pages/workspaces/authenticatedRoute/AuthenticatedRoute";
import analytics from "../../config/analytics/analytics";
import openReplayTracker from "../../config/openReplay";
import { ThemeComponent, themeList } from "@src/theme/Theme";
import * as Sentry from "@sentry/react";

const Login = lazy(() => import("../pages/authentication/login/Login"));
const RenewPassword = lazy(() => import("../pages/authentication/renewPassword/RenewPassword"));
const Welcome = lazy(() => import("../pages/authentication/welcome/Welcome"));
const WorkspacesRoutes = lazy(() => import("../pages/workspaces/Routes"));
const NotFoundPage = lazy(() => import("../components/404/NotFoundPage"));
const PublicRoutes = lazy(() => import("@pages/public/Routes"));
const JoinWorkspace = lazy(() => import("@pages/authentication/joinWorkspace/JoinWorkspace"));
const PublicOffer = lazy(() => import("@pages/public/offer/PublicOffer"));
const TemplateLogin = lazy(() => import("@pages/public/templateLogin/TemplateLogin"));
const PublicPriceModelCombination = lazy(
	() => import("@pages/public/priceModelCombination/PublicPriceModelCombination")
);

const GlobalRoutes = () => {
	const location = useLocation();

	const createEventAnalytics = createEventAnalyticsMutation();

	useEffect(() => {
		const path = location.pathname;
		if (!path.includes("workspaces/") && !path.includes("public") && !path.includes("offer")) {
			analytics.page({
				path: path,
				title: "Priceit",
			});
		}
		if (
			!path.includes("pricingTools/buildingTools/packagePricing") &&
			!path.includes("/public/package") &&
			!path.includes("pricingTools/valueBasedPricing/psm")
		) {
			document.title = "Givemefive";
		}
	}, [location.pathname]);

	useEffect(() => {
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = "525c3ab5-d515-44c6-a744-681d0a9b692f";

		if (location.pathname.split("/")[1] !== "offer") {
			const d = document;
			const s = d.createElement("script");
			s.src = "https://client.crisp.chat/l.js";
			d.getElementsByTagName("head")[0].appendChild(s);
		}
		analytics.on("page", ({ payload: { options, properties }, instance: { user } }) => {
			if (!properties.path.startsWith("/public/package")) {
				createEventAnalytics({
					variables: {
						createEventAnalytics: {
							page: properties.path,
							mail: user()?.traits?.email || options.mail || "unknown",
							workspaceName: options?.workspaceName || "",
							workspaceActive: options?.workspaceActive || false,
							workspaceActivationDate: options?.workspaceActivationDate ?? null,
							workspaceActiveToDate: options?.workspaceActiveToDate ?? null,
							action: options?.action ?? "openPage",
						},
					},
				});
			}
		});
		if (process.env.OPEN_REPLAY_PROJECT_KEY) {
			openReplayTracker.start();
		}
	}, []);

	useEffect(() => {
		Sentry.setTag("openReplaySession.id", openReplayTracker.getSessionID());
		Sentry.setTag("openReplaySessionURL", openReplayTracker.getSessionURL());
	}, [openReplayTracker.getSessionID()]);

	const fontFamily =
		location.pathname.includes("offer") || location.pathname.includes("public")
			? "Poppins"
			: "Lato";
	return (
		<RecoilRoot>
			<Suspense
				fallback={
					<WrapperLoader>
						<Loader />
					</WrapperLoader>
				}
			>
				<>
					<PStyle fontFamily={fontFamily} />
					<Style fontFamily={fontFamily} />
					<Routes>
						{/* Redirection for old urls -> To delete in a couple of months */}
						<Route
							path="/"
							element={
								<Navigate
									to={
										location?.hash?.[0] === "#"
											? location?.hash?.split("#")[1]
											: "/login"
									}
									replace
								/>
							}
						/>
						<Route path="/login" element={<Login />} />
						<Route path="/workspaces" element={<Navigate to="/login" replace />} />
						<Route
							path="/workspaces/:workspaceId/packagesPricing/:token"
							element={
								<AuthenticatedRoute>
									<PublicPriceModelCombination />
								</AuthenticatedRoute>
							}
						/>
						<Route
							path="/workspaces/:workspaceId/*"
							element={
								<AuthenticatedRoute>
									<Suspense fallback={<Loader />}>
										<WorkspacesRoutes />
									</Suspense>
								</AuthenticatedRoute>
							}
						/>
						<Route path="/renewPassword" element={<RenewPassword />} />
						<Route path="/public/*" element={<PublicRoutes />} />
						<Route
							path="/offer/:workspaceName/:token/:signatureToken"
							element={<PublicOffer />}
						/>

						<Route path="/offer/:workspaceName/:token" element={<PublicOffer />} />
						<Route
							path="/myOffer/:workspaceName/:templateToken/:userToken"
							element={<PublicOffer />}
						/>
						<Route
							path="/welcome"
							element={
								<ThemeComponent mode={"dark"} theme={themeList.theme1}>
									<Welcome />
								</ThemeComponent>
							}
						/>
						<Route path="/join" element={<JoinWorkspace />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</>
			</Suspense>
		</RecoilRoot>
	);
};

export default GlobalRoutes;
